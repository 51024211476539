.day {
  text-align: center;
  font-weight: bold;
  color: white;
  background-color: #009926;
  margin-top: 1em;
  padding-top: 4px;
}

.custom-table {
  border-collapse: collapse;
  margin-bottom: 1em;
}

.custom-table, .custom-table th, .custom-table td {
  border: thin solid black
}

.custom-table th, .custom-table td {
  padding-left: .5em;
  padding-right: .5em;
}

.no-bullets ul {
    list-style-type: none;
    padding-left: 0;
}
